export const imgConfig = {
  "file": "loopring.png", "frames": {
    "0XBTC": {"x": 248, "y": 282, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "1INCH": {"x": 207, "y": 322, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "AAVE": {"x": 166, "y": 362, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "AC": {"x": 125, "y": 402, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ADX": {"x": 84, "y": 442, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ALCX": {"x": 453, "y": 82, "w": 36, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ALEND": {"x": 453, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ALINK": {"x": 412, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "AMP": {"x": 371, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ANT": {"x": 248, "y": 322, "w": 36, "h": 35, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "AUC": {"x": 166, "y": 478, "w": 33, "h": 32, "offX": 3, "offY": 2, "sourceW": 37, "sourceH": 36},
    "AUSDC": {"x": 330, "y": 162, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BADGER": {"x": 125, "y": 442, "w": 37, "h": 35, "offX": 0, "offY": 1, "sourceW": 37, "sourceH": 36},
    "BAL": {"x": 289, "y": 202, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BAND": {"x": 100, "y": 482, "w": 19, "h": 26, "offX": 9, "offY": 5, "sourceW": 37, "sourceH": 36},
    "BAT": {"x": 248, "y": 242, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BCDT": {"x": 205, "y": 442, "w": 27, "h": 36, "offX": 5, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BCP": {"x": 207, "y": 282, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BEL": {"x": 166, "y": 322, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BKT": {"x": 125, "y": 362, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BNT": {"x": 84, "y": 402, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BOR": {"x": 43, "y": 442, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BTC2XFLI": {"x": 289, "y": 242, "w": 36, "h": 36, "offX": 1, "offY": 0, "sourceW": 38, "sourceH": 36},
    "BTU": {"x": 456, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BUSD": {"x": 412, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "BZRX": {"x": 371, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "CDAI": {"x": 330, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "CEL": {"x": 289, "y": 162, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "CETH": {"x": 248, "y": 202, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "COMP": {"x": 207, "y": 242, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "CRV": {"x": 166, "y": 282, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "CUSDC": {"x": 125, "y": 322, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "CVT": {"x": 409, "y": 202, "w": 33, "h": 30, "offX": 2, "offY": 3, "sourceW": 37, "sourceH": 36},
    "DAI": {"x": 84, "y": 362, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "DEP": {"x": 370, "y": 240, "w": 31, "h": 34, "offX": 3, "offY": 1, "sourceW": 37, "sourceH": 36},
    "DOUGH": {"x": 43, "y": 402, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "DPI": {"x": 2, "y": 442, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "DPR": {"x": 415, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "DXD": {"x": 371, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ENJ": {"x": 330, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ENTRP": {"x": 236, "y": 442, "w": 21, "h": 30, "offX": 8, "offY": 3, "sourceW": 37, "sourceH": 36},
    "ETH-1": {"x": 289, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ETH": {"x": 248, "y": 162, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "FARM": {"x": 166, "y": 442, "w": 35, "h": 32, "offX": 1, "offY": 2, "sourceW": 37, "sourceH": 36},
    "FIN": {"x": 207, "y": 202, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "FLI": {"x": 166, "y": 242, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "FLX": {"x": 2, "y": 2, "w": 40, "h": 36, "offX": 0, "offY": 0, "sourceW": 40, "sourceH": 36},
    "FUSE": {"x": 207, "y": 362, "w": 35, "h": 36, "offX": 1, "offY": 0, "sourceW": 37, "sourceH": 36},
    "GNO": {"x": 330, "y": 202, "w": 36, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "GRG": {"x": 84, "y": 322, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "GRID": {"x": 43, "y": 362, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "GRT": {"x": 2, "y": 402, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "HBTC": {"x": 205, "y": 402, "w": 32, "h": 36, "offX": 2, "offY": 0, "sourceW": 37, "sourceH": 36},
    "HEX": {"x": 452, "y": 122, "w": 37, "h": 32, "offX": 0, "offY": 2, "sourceW": 37, "sourceH": 36},
    "HT": {"x": 374, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ICHI": {"x": 330, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "IDLE": {"x": 289, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "INDEX": {"x": 241, "y": 402, "w": 24, "h": 36, "offX": 7, "offY": 0, "sourceW": 37, "sourceH": 36},
    "JRT": {"x": 248, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "KAI": {"x": 125, "y": 481, "w": 29, "h": 29, "offX": 4, "offY": 3, "sourceW": 37, "sourceH": 36},
    "KEEP": {"x": 207, "y": 162, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "KNC": {"x": 203, "y": 482, "w": 19, "h": 26, "offX": 9, "offY": 5, "sourceW": 37, "sourceH": 36},
    "KP3R": {"x": 166, "y": 202, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "LINK": {"x": 125, "y": 242, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "LRC": {"x": 412, "y": 122, "w": 36, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "MASK": {"x": 84, "y": 282, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "MCB": {"x": 63, "y": 482, "w": 33, "h": 18, "offX": 2, "offY": 9, "sourceW": 37, "sourceH": 36},
    "MKR": {"x": 43, "y": 322, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "MTA": {"x": 2, "y": 362, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "NEC": {"x": 333, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "NEST": {"x": 289, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "NIOX": {"x": 411, "y": 162, "w": 33, "h": 36, "offX": 2, "offY": 0, "sourceW": 38, "sourceH": 36},
    "NMR": {"x": 248, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "OBTC": {"x": 207, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "OGN": {"x": 166, "y": 162, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "OMG": {"x": 125, "y": 202, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ONG": {"x": 84, "y": 242, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "OVR": {"x": 43, "y": 282, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "PAX": {"x": 2, "y": 322, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "PBTC": {"x": 292, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "PLTC": {"x": 248, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "PNK": {"x": 329, "y": 242, "w": 37, "h": 34, "offX": 0, "offY": 1, "sourceW": 37, "sourceH": 36},
    "PNT": {"x": 207, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "QCAD": {"x": 166, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "RAI": {"x": 166, "y": 402, "w": 35, "h": 36, "offX": 1, "offY": 0, "sourceW": 37, "sourceH": 36},
    "REN": {"x": 125, "y": 162, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "RENBTC": {"x": 84, "y": 202, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "RENDOGE": {"x": 43, "y": 242, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "REP": {"x": 2, "y": 482, "w": 23, "h": 28, "offX": 7, "offY": 4, "sourceW": 37, "sourceH": 36},
    "REPT": {"x": 2, "y": 282, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "RFOX": {"x": 251, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "RGT": {"x": 207, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "RICE": {"x": 166, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "RPL": {"x": 125, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "RSPT": {"x": 84, "y": 162, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "RSR": {"x": 43, "y": 202, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "SMARTCREDIT": {"x": 2, "y": 242, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "SNT": {"x": 210, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "SNX": {"x": 166, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "STAKE": {"x": 125, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "SUSD": {"x": 84, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "SUSHI": {"x": 370, "y": 202, "w": 35, "h": 34, "offX": 1, "offY": 1, "sourceW": 37, "sourceH": 36},
    "SX": {"x": 289, "y": 282, "w": 35, "h": 34, "offX": 1, "offY": 1, "sourceW": 37, "sourceH": 36},
    "TEL": {"x": 43, "y": 162, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "TRB": {"x": 2, "y": 202, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "TRYB": {"x": 169, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "TUSD": {"x": 125, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "UBT": {"x": 84, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "UMA": {"x": 43, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "UNI": {"x": 2, "y": 162, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "USDC": {"x": 371, "y": 162, "w": 36, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "USDT": {"x": 128, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "VBZRX": {"x": 84, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "VETH": {"x": 43, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "VSP": {"x": 2, "y": 122, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "WBTC": {"x": 87, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "WNXM": {"x": 43, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "WOO": {"x": 29, "y": 482, "w": 30, "h": 21, "offX": 3, "offY": 8, "sourceW": 37, "sourceH": 36},
    "YFI": {"x": 2, "y": 82, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "YFII": {"x": 46, "y": 2, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "YPIE": {"x": 2, "y": 42, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36},
    "ZRX": {"x": 125, "y": 282, "w": 37, "h": 36, "offX": 0, "offY": 0, "sourceW": 37, "sourceH": 36}
  }
}