export default {
  labelConnectUsSimple: "Please <1>contact us</1>.",
  labelConnectUs:
    "If you believe this is indeed a bug, please <1>contact us</1>. <2/> We would appreciate your feedback.",
  errorBase: "Oops! Something went wrong.",
  errorTimeout: "Oops! Something went wrong at network.",
  errorLoading: "Loading! Please wait...",
  error404: "404! Page can't be reached.",
  errorMaintain: "System update! Please wait...",
  errorMessageNoNetwork: "Oops! Something went wrong at service.",
  errorUnknown: "Unknown Error",
  errorOnFromSubmit: "Submit request Info has Error!",
  errorWrongAccount: "Error on account Info!",
  errorWrongToken: "Error on Token Info!",
  errorWrongApikey: "Error on signature or no API key.",
  errorWrongBalance: "Insufficient balance",
  errorMinimumOrder: "Trade amount is minimum",
  errorMaximumOrder: "Trade amount is maximum",
  errorOnFrozen: "Some of your assets are frozen, please try again later.",
  errorAboutFee: "Fee token is not enough or incorrect.",
  errorProviderError:
    "Global ethereum is not {{name}}, please disable your other Wallet Plugin.",
  errorOnStorageId: "Error on wrong StorageId",
  errorOnNoRecipient: "Error on no Receiver",
  errorNoMarket: "Market is not supported.",
  errorOnGas: "Error on Gas Info",
  errorOnCancelOrders: "Error on cancel order",
  errorInvalidHash: "Invalid Hash",
  errorInvalidOrderId: "Invalid OrderId",
  errorOnRate: "Error on submit rate value",
  errorForExistOrder: "Order does not exist",
  errorOrderExpired: "Order has expired",
  errorDisableOtherWalletForCurrent:
    "Global ethereum is not {{name}}, please disable other Wallet Plugin.",
  errorGenerateEddsa: "Generate EdDSA key has failed.",
  errorNotInstallGME:
    "Please install the Gamestop Wallet browser extension. Then create, deposit, and activate the wallet before connecting to Loopring.io.",
  errorLinKWalletApp: "<0>app market</0>",
  errorMintOverlap: "This NFT metadata is already minted in your Wallet!",
  errorJSONStringify: "Wrong JSON format",
  errorCollectionMetadataNoTileUri: "Tile uri is required",
  errorCollectionNoName: "Name is required",
  errorCollectionSameName: "You already hava an collection call {{name}} ",
};
