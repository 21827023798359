export const firebaseIOConfig = {
  apiKey: "AIzaSyC484SNh-OZWco7o1xUC4UGVGVf0yZU__s",
  authDomain: "loopring-d0829.firebaseapp.com",
  projectId: "loopring-d0829",
  storageBucket: "loopring-d0829.appspot.com",
  messagingSenderId: "372617031978",
  appId: "1:372617031978:web:15a3f35c4adbd679dc6e53",
  measurementId: "G-5TWTDQ8J7J",
};

export const firebaseBridgeConfig = {
  apiKey: "AIzaSyC484SNh-OZWco7o1xUC4UGVGVf0yZU__s",
  authDomain: "loopring-d0829.firebaseapp.com",
  projectId: "loopring-d0829",
  storageBucket: "loopring-d0829.appspot.com",
  messagingSenderId: "372617031978",
  appId: "1:372617031978:web:bcc50f63d79ef868dc6e53",
  measurementId: "G-P4XEJ3CY0J",
};
