/* eslint-disable max-len */

export default {
  labelErrorTitle: "Error Detail: ",
  labelNoContent: "No Content",
  labelError: "Error",
  tokenEnter: "Enter Token",
  tokenEnterPaymentToken: "",
  tokenMax: "Available:",
  tokenNFTMaxMINT: "Max:",
  tokenHave: "Available:",
  tokenEnterReceiveToken: "",
  tokenSelectToken: "Select Token",
  tokenExchange: "exchange",
  tokenNotEnough: "Insufficient {{belong}} balance",
  tokenSearchCoin: "Search Token Symbol",
  swapTitle: "Swap",
  swapTolerance: "Slippage Tolerance",
  labelSwapToleranceTooltips:
    "Your trade will revert if the price changes unfavorably by more than this percentage.",
  swapPriceImpact: "Price Impact",
  labelSwapPriceImpactTooltips:
    "The difference between market price and estimated price due to trade size",
  swapMinReceive: "Minimum Received",
  swapMinReceiveS: "Min. Received",
  labelSwapMinReceiveTooltips:
    "The pool price changes dynamically, the price you see when placing an order may be inconsistent with the final transaction price; also the received amount needs to deduct the fees from converted amount. The protocol can guarantee that the received token is at least this amount.",
  swapFee: "Estimate Fee",
  swapFeeS: "Est. Fee",
  labelSwapFeeTooltips:
    "The trading fee is determined by your VIP level and the size of your trade. Small trades (below ~$100) incur a higher fee. Please review the fee before confirming.",
  swapBtn: "swap",
  goBack: "go back",
  resetTitle: "Reset Layer 2 Keypair",
  restLabelEnterToken: "Select Reset cause token",
  resetDescription:
    "Each account on Loopring L2 needs an EdDSA private key (the account key) to sign off-chain (aka Layer 2) requests. You can reset the EdDSA keypair at any time.",
  resetFee: "Fee {{count}} GAS ≈ ${{price}}",
  resetLabelBtn: "Reset",
  labelActiveEnterToken: "Select payment token",
  labelActiveAccountDescription:
    "You have enough balance to pay for Layer 2 creation as below.",
  labelActiveAccountFee: "Fee {{count}} GAS ≈ ${{price}}",
  labelActiveAccountBtn: "Activate Account",
  depositLabelEnterToken: "Select Layer 1 Token",
  depositDescription:
    "Once your deposit is confirmed on Ethereum, \n it will be added to your balance within 2 minutes.",
  depositAndActiveDescription:
    "Make a deposit to activate your Loopring L2 account. Once your deposit is <1>confirmed on Ethereum</1>, it will be added to your balance within <3>2 minutes</3>.",
  depositLabelRefer: "Referral address, Account ID or ENS. (Optional)",
  depositLabelPlaceholder: "address, Account ID or ENS",
  withdrawDescription:
    "Your withdrawal will be processed in the next batch,\n which usually takes 30 minutes to 2 hours.\n (There will be a large delay if the Ethereum gas price exceeds 500 GWei.）",
  withdrawTypeLabelFast: "Fast (15 seconds at the earliest)",
  withdrawTypeLabelStandard: "Standard (about 25 minutes)",
  labelConnectWallet: "Connect wallet",

  labelCustomer: "Custom",
  labelChange24h: "{{timeUnit}} Change",
  labelDepth: "Depth",
  labelTrend: "Price",
  label1W: "W1",
  label1H: "H1",
  label1D: "D1",
  labelCopyAddress: "Copy",
  labelDisconnect: "Disconnect",
  labelLockLayer2: "Lock",
  labelUnLockLayer2: "Unlock",
  labelSwitchAccount: "Switch",
  labelViewEth: "View on Etherscan",
  labelQRCode: "View QR Code",
  labelShowAccountInfo: "Show account information",
  labelAssetTitle: "Loopring L2 Total Assets",
  labelAssetMobileTitle: "L2 Assets",
  labelShowAccount: "Show or Hide Assets",
  labelLevel: "VIP Level",
  labelOrderbook: "Order book",
  labelSetPublicKey: "Set EdDSA Public Key",
  labelTitleSecurity: "Security",
  labelTitleResetL2Keypair: "Reset Loopring L2 Keypair",
  labelBtnReset: "Reset",
  labelHadChangPassword: "You changed your keypair {{passDay}} ago.",
  labelTitleForceWithdraw: "Force Withdraw",
  labelBtnForceWithdraw: "Force Withdraw",
  labelTitleExportAccount: "Export Account",
  descriptionExportAccount:
    "In order to access the Loopring L2 APIs, you will need to export a security key.",
  labelBtnExportAccount: "Export Account",
  labelTitlePreferences: "Preferences",
  labelTitleLayout: "Custom Setting",
  whichColorIsUp: "<0>{{up}} up</0> and <2>{{down}} down</2> ",
  labelTradeFeeLevel: "Your Trading Fee Level:",
  labelLanguage: "Language",
  labelCurrency: "Currency",
  currencySetting: "Currency",
  labelColors: "Colors",
  labelTheme: "Dark Theme",
  labelthemeLight: "Light theme",
  labelthemeDark: "Dark theme",
  labelgreen: "green",
  labelred: "red",
  langZH: "Chinese",
  langEN: "English",
  labelUSDollar: "USD",
  labelCNYYuan: "CNY",
  labelMaker: "Maker",
  labelTaker: "Taker",
  labelAssetsTitle: "Assets",
  labelVolume: "volume",
  labelAmount: "Amount",
  labelLiquidityDeposit: "Subscribe",
  labelLiquidityWithdraw: "Redeem",
  labelAvailable: "Available:",
  labelTokenAmount: "Amount",
  labelRemoveLiquidityBtn: "Remove Liquidity",
  labelAddLiquidityBtn: "Add Liquidity",
  labelEndLiquidityBtn: "Ended",
  labelTradePanelHideOtherPairs: "Hide other pairs",
  labelLPTokens: "LP Tokens",
  labelMyLPToken: "My Liquidity Token",
  labelMyLPAToken: "My {{symbol}}",
  labelMyLPBToken: "My {{symbol}}",
  labelMyLPAmountFor: "My Ratio",
  labelTrade: "Trade",
  labelAmmList: "AMM List",
  labelMyPoolShare: "My Pool Share",
  labelFee: "Fee",
  labelLPTotal: "Total ",
  labelReward: "Rewards",
  labelMyReward: "My Rewards",
  labelDate: "Date",
  labelBack: "Back",
  labelAPR: "APR",
  label24Volume: "24h Volume",
  label24VolumeSimple: "24h Vol",
  labelTVL: "TVL",
  labelAmmTotalToken: "Tokens in AMM",
  labelNoActiveEvent: "No event",
  labelNew: "New",
  labelAccount: "Account",
  labelAll: "All",
  labelMe: "My Liquidity",
  labelMyTrade: "My Trades",
  labelRecent: "Market Trades",
  labelMyAmm: "My AMM Liquidity",
  labelMyAmmRecord: "My AMM Records",
  labelCurrentActivities: "Current Activities",
  labelPastActivities: "Past Activities",
  labelTotalPositionValue: "Total Investment",
  labelFeeRewards: "Fee Rewards",
  labelMiningRewards: "Mining Rewards",
  labelLiquidityValue: "Liquidity Value",
  labelCopyAddClip: "Address Copied to Clipboard!",
  labelPleaseInputWalletAddress: "Please input address / ENS / Account ID",
  labelEmptyDefault: "No data to display",
  labelUnlockAccount: "Unlock Account",
  labelLockWallet: "Lock Wallet",
  labelAssetsDistribution: "Assets Distribution",
  labelTotalAssets: "Assets Trend",
  labelTxnPageTitle: "Deposit/Withdraw",
  labelTradePageTitle: "Trade History",
  labelAmmPageTitle: "AMM Records",
  labelSwapSuccess: "Swap successful!",
  labelOrderProcessing: "Order Placed!",
  labelSwapFailed: "Swap failed!",
  labelJoinAmmSuccess: "Successfully joined AMM Pool!",
  labelJoinAmmFailed: "Failed to join AMM Pool!",
  labelExitAmmSuccess: "Successfully exited the AMM Pool!",
  labelExitAmmFailed: "Failed to exit the AMM Pool!",
  labelConnectBy: "Connected with <1>{{connectBy}}</1>",
  labelWrongNetwork: "Wrong network",
  labelActivatedAccountDeposit:
    "Please deposit to activate your Layer 2 account",
  labelActivatedAccountNotSupport: "Your wallet does not support Loopring L2",
  labelActivatedAccountNotSupportDes:
    "Please connect with a different wallet or download the Loopring Wallet mobile app.",
  labelNotAllowForSmartWalletTitle: "Apologize",
  labelProcessing: "Processing",
  labelProviderProcessing: "Connect Wallet with {{name}}...",
  labelProviderCommonProcessDescribe:
    "Please click the ‘Approve’ button on the {{name}} popup window. If the {{name}} popup window is dismissed, please manually click <5></5> on your browser toolbar.",
  labelWalletConnectProcessDescribe:
    "Please wait for WalletConnect provider to confirm processing",
  labelWalletConnectQRCode:
    "Please scan the QR code with a WalletConnect compatible application",
  labelSuccessConnect: "Successfully Connected with {{providerName}}",
  labelSuccessConnectDescribe: "Congratulations, Connection Successful!",
  labelCopyClipBoard: "Copy",
  labelCopyManually: "Manually Selected & Copy:",
  labelRejectOrError:
    "Request was rejected or some unknown error occurred, please retry",
  labelWalletConnectProcessDescribe2: "Please click ‘Approve’ on your device.",
  labelUnlockProcessing: "Unlocking Layer 2...",
  labelFailedConnect: "Connection Failed",
  // labelTokenAccess:'Waiting to approve {{symbol}} access!',
  labelTokenAccess: "Waiting for approval",
  labelFailedTokenAccess: "Failed to approve {{symbol}} access!",
  labelSuccessTokenAccess: "Congratulations, you have {{symbol}} access!",
  labelSuccessUnlockDescribe: "Congratulations, Successfully Unlocked!",
  labelSuccessUnlock: "Unlock Successful!",

  labelActivateAccount: "Activate Account",
  labelClose: "Close",
  labelRetry: "Retry",
  labelTryNext: "Sign Again",
  labelQuotePageFavourite: "Favourites",
  labelQuotePageAll: "All",
  labelQuotePageTradeRanking: "Tournaments",
  labelFailedUnlock: "Unlock Failed",
  labelFailedUpdateAcc: "Update Account Failed",
  labelUpdateAccSigWarning:
    "Your Wallet does not support current sig function, it will try another one.",
  labelUpdateAccUserDenied: "Signature request was rejected!",
  labelCreateLayer2Title: "Create Layer 2 Account",
  labelCreateAccount: "Create Layer 2 Account",
  labelUpdateAccount: "Update Layer 2 Account",
  labelTryAnother: "Try Another Sig Method",
  labelCancel: "Cancel",
  describeTitleNoAccount:
    "As Ethereum's first ever zkRollup, Loopring Layer 2 allows you to avoid costly gas fees and network congestion with the same security as mainnet - 100x cheaper and faster.",
  describeTitleOpenAccounting:
    "Your deposit has been submitted to Ethereum.\n Please wait...",
  describeTitleOnErrorNetwork:
    "Your current network is not supported by Loopring!\n Please change network via {{connectName}}.",
  describeTitleNotActive:
    "As Ethereum's first ever zkRollup, Loopring Layer 2 allows you to avoid costly gas fees and network congestion with the same security as mainnet - 100x cheaper and faster.",
  describeTitleConnectToWallet:
    "As Ethereum's first ever zkRollup, Loopring Layer 2 allows you to avoid costly gas fees and network congestion with the same security as mainnet - 100x cheaper and faster.",
  describeWhatIsGuardian: "What is a Loopring guardian",
  describeTitleConnectToWalletAsGuardian:
    "Connect a wallet to assign it as a guardian!",
  describeTitleLocked: "Unlock your account to view your assets.",
  labelLiquidityPageTitle: "AMM Pools",
  labelMinReceive: "Minimum Received Amount",
  labelFilter: "Search",
  labelMiningPageTitle: "Loopring Liquidity Mining",
  labelMiningPageViceTitle:
    "Earn rewards for contributing to Loopring liquidity.",
  labelMiningActiveDate: "Active Date",
  labelMiningLiquidity: "Liquidity",
  labelMiningActivityReward: "Activity Reward",
  labelMiningMyShare: "My Share",
  labelMiningMyReward: "My Reward",
  labelMiningPlaceOrderBtn: "Place Order",
  labelMiningViewDetails: "View details",
  labelMiningMaxSpread: "Max Spread",
  labelMiningReward: "Reward",
  labelCookiesAgree: "Agree",
  labelLimitMin: "Minimum of {{arg}}",
  labelLimitMinUnknown: "Order too small",
  labelLimitMax: "Maximum of {{arg}}",
  labelOrderSmall: "Order too small (>= 100.5LRC)",
  labelEnterAmount: "Enter amount",
  labelAgreeLoopringTxt: "Allow Loopring to use Cookies.",
  labelLayer2HistoryTransactions: "Transfer",
  labelLayer2HistoryTrades: "Trades",
  labelLayer2HistoryAmmRecords: "AMM Records",
  labelTxnDetailHeader: "Transaction",
  labelDTxnDetailHeader: "Deposit Record",
  labelWTxnDetailHeader: "Withdraw Record",
  labelTTxnDetailHeader: "Transfer Record",
  labelTxnDetailHash: "Layer 2 Hash",
  labelTxnDetailHashLv1: "Eth Hash",
  labelTxnDetailStatus: "Status",
  labelTxnDetailTime: "Time",
  labelTxnDetailFrom: "From",
  labelTxnDetailTo: "To",
  labelTxnDetailAmount: "Amount",
  labelTxnDetailFee: "Fee",
  labelTxnDetailMemo: "Memo",
  labelTxnDetailProcessed: "PROCESSED",
  labelTxnDetailProcessing: "PROCESSING",
  labelTxnDetailFailed: "FAILED",
  labelAgreeConfirm: "Agree",
  labelDisAgreeConfirm: "Disagree",
  labelImpactAgree: 'Type "AGREE" to confirm.',
  labelImpactTitle: "Swap Requires Confirmation",
  labelPriceExtraGreat:
    "The price you set is {{compare}} than 20% of the market price. Are you sure you want to complete this order?",
  labelPriceCompareGreat: "Greater",
  labelPriceCompareLess: "Less",
  labelImpactExtraGreat:
    "Your transaction amount will affect the pool price by <1>{{value}}</1>. Are you sure you want to swap?",
  labelCalculating: "Calculating...",
  labelFeeCalculating: "Calculating fee...",
  labelAmmMyTransactions: "My Transactions",
  labelAmmAllTransactions: "All Transactions",
  labelWaitForAuth: "Waiting for signature",
  labelSignDenied: "Signature request was rejected",
  labelFirstSignDenied: "Your wallet doesn't support this signature method",
  labelUpdateAccountSuccess: "Congratulations!",
  labelUpdateAccountSuccess2:
    "You have successfully activated your Loopring L2 account!",
  labelResetAccountSuccess: "Congratulations!",
  labelResetAccountSuccess2:
    "You have successfully reset your Loopring L2 account keypair!",
  labelUpdateAccountSubmit: "Activating Tx submitted.",
  labelUnlockAccountSuccess: "Unlock successful!",
  labelUnlockAccountFailed: "Unlock failed!",
  labelNotSupportTitle: "Information",
  labelNotAllowTrade:
    "Unfortunately we are unable to provide Order and AMM Deposit services due to your IP address as per our Terms of Use.",
  labelKnown: "OK",
  labelResetAccount: "Reset Layer 2 Account",
  labelExportAccount: "Export Account",
  labelExportAccountNoPhotos: "No Photos",
  labelExportAccountDescription: "Please keep your API key secure.",
  labelExportAccountCopy: "Copy",
  labelExportAccountSuccess: "Export Account successful!",
  labelExportAccountFailed: "Export Account has failed!",
  // labelCreateAccountApproveWaitForAuth: 'Waiting for <1>{{symbol}}</1> Approve...',
  labelCreateAccountApproveDenied: "Signature request was rejected",
  labelAmmSwitch: "switch",
  labelCreateAccountDepositDenied: "Signature request was rejected",
  labelSlippageAlert:
    "Your slippage tolerance is rather high which could result in less tokens received.",
  labelOrderGroup: "Order Records",
  labelOrderTableOpenOrder: "Open Order",
  labelOrderTableOrderHistory: "Order History",
  labelResetLayout: "Reset Layout",
  labelResetMobileLayout: "Reset",
  labelBtnFix: "reset",
  labelProSell: "Sell",
  labelProBuy: "Buy",
  labelProLimit: "Limit",
  labelProMarket: "Market",
  labelProPrice: "Price",
  labelProBaseLabel: "Amount",
  labelProQuoteLabel: "Total",
  labelProLimitBtn: "{{tradeType}} {{symbol}}",
  labelProMarketBtn: "{{tradeType}} {{symbol}}",
  labelProOrderbook: "Orderbook",
  labelProTrades: "Trades",

  labelProToolbar24hChange: "24h Change",
  labelProToolbar24hHigh: "24h High",
  labelProToolbar24hLow: "24h Low",
  labelProToolbar24hBaseVol: "24h Volume({{symbol}})",
  labelProToolbar24hQuoteVol: "24h Volume({{symbol}})",
  labelErrorPricePrecisionLimit:
    "{{symbol}} price only allows {{decimal}} decimals.",
  labelDepthPrice: "Price({{symbol}})",
  labelDepthAmount: "Accum({{symbol}})",
  labelDepthTotal: "Accumulation",

  labelProChartTitle: "Chart",
  labelProTimeDefault: "Time(1m)",
  labelProTime1m: "1m",
  labelProTime5m: "5m",
  labelProTime15m: "15m",
  labelProTime30m: "30m",
  labelProTime1H: "1H",
  labelProTime2H: "2H",
  labelProTime4H: "4H",
  labelProTime12H: "12H",
  labelProTime1D: "1D",
  labelProTime1W: "1W",
  labelProTime1M: "1M",
  labelProChartTradingView: "Candlestick",
  labelProChartDepth: "Depth",
  labelProOrderPrice: "Order Price",
  labelProOrderTotalAmount: "Accumulative Amount",

  labelSwapCancelled: "Swap is cancelled.",
  labelSuccessfully: "Successful",
  labelWarning: "Warning",
  labelFailure: "Failure",
  labelPrompt: "Prompt",

  // labelSwapCancelled: 'Swap is cancelled.',
  // labelSuccessfully: 'Successfully',
  // labelWarning: 'Warning',
  // labelFailure: 'Failure',
  // labelPrompt: 'Prompt',

  labelComingSoon: "Coming Soon",
  labelTradeProHideOtherPairs: "Hide other trading pairs",
  labelCancelAllOrders: "Confirm cancelling all orders?",
  labelConfirm: "Confirm",
  labelSettingFee: "Token Order for Fees",
  descriptionSettingFee:
    "Change the token priority order to adjust which tokens will be used for fees first.",
  labelBtnEdit: "Edit",
  labelSettingChargeFeeOrder: "Token Order for Fees",
  desSettingChargeFeeOrder:
    "Loopring L2 will use this token order when processing fees.",
  labelReset: "Reset",
  labelQueryFeeOK: "Save",
  depositLimit:
    "Limit Orders \n Used to set the maximum or minimum price \n at which you are willing to buy or sell.",
  depositMarket:
    "Market Orders \n Used to buy or sell immediately \n at the current market price.",
  labelTransactions: "Transactions",
  labelMyRewards: "My Rewards",
  labelClearAll: "Clear All",
  labelProviderAgree:
    "I have read, understand, and agree to the <1> Terms of Service </1>.",
  labelNFTName: "Name:",
  labelNFTDetail: "Details",
  labelNFTTokenStandard: "Token Standard:",
  labelNFTTokenMinted: "Token Minted:",
  labelNFTDescription: "Description:",
  labelNFTDate: "Date:",
  labelNFTDeployContract: "Deploy",
  labelNFTSend: "Send:",
  labelNFTDeploy: "Deploy:",
  labelNFTDeploying: "Deploying",
  labelNFTMyNFT: "My NFTs",
  labelNFTTokenID: "ID:",
  labelNFTTYPE: "Token Standard:",
  labelNFTID: "ID:",
  labelNFTMinter: "Minter:",
  labelNFTMint: "Mint NFT",
  labelNFTTitleMyNFT: "My NFTs",
  labelNFTTOTAL: "Amount:",
  labelInformation: "Notification",
  labelNoticeForProvider:
    "Loopring currently supports the following wallet connections: {{name}}. Please make sure to use one of these when attempting to connect.",
  labelIKnow: "OK",
  labelYes: "Yes",
  labelNo: "No",
  labelNoticeForNoMetaNFT:
    "Your Minted NFT does not contain Metadata or media information. \n Are you sure you still wish to {{ method }} this NFT?",
  labelAgreeConfirmNotShowAgain: "I know & not show again",
  labelInvalidCID:
    "Invalid CID. CIDv0 is start with `Qm`, CIDv1 only works for dag-pb",
  labelInvalidAddress: "Invalid address, ENS",
  labelInvalidisCFAddress:
    "Loopring Counterfactual wallet is disabled {{way}} {{token}}",
  labelInvalidisContract1XAddress:
    "Loopring wallet 1.x is disabled {{way}} {{token}}",
  labelInvalidisContractAddress:
    "{{way}} of {{token}} to Contract wallet is not available ",
  labelInvalidisLoopringAddress:
    "This address does not yet have an active Loopring L2, {{way}} of {{token}} is disabled!",
  labelInvalidisSameAddress: "Cannot {{way}} to your own address.",
  labelTradeRaceRanking: "Trading Leaderboard",
  labelTradeRaceYourVolume: "Your trading volume",
  labelTradeRaceYourRanking: "Your ranking",
  labelTradeRaceGoTrading: "Go to trade",
  labelTradeReadRule: "Read Rules",
  labelTradeRaceRewards: "Rewards",
  labelTradeRaceRules: "Activity Rules",
  labelTradeRaceStart: "Activity ends in:",
  labelTradeRaceReady: "Activity starts in:",
  labelTradeRaceEnd: "Activity has ended",
  labelDay: "Days",
  labelHours: "Hours",
  labelMinutes: "Minutes",
  labelSeconds: "Seconds",
  labelIsNotFeeToken: "Please deposit {{symbol}} to activate Loopring L2.",
  labelIsETHDepositAlert: "Please reserve enough ETH for gas!",
  labelIsNotEnoughFeeToken:
    "Please deposit {{fee}} {{symbol}} to cover the Layer 2 activation fee",
  depositNFTAddressLabelPlaceholder: "please input NFT contract address...",
  mintNFTAddressLabelPlaceholder:
    "(CIDv0 or dag-pb CIDv1) eg: QmbWqxBEKC3P8tqsKc98xmWNzrzDtRLMiMPL8wBuTGsMnR",
  depositNFTIdLabelPlaceholder: "please input NFT id...",
  nftDepositDescription:
    "Creates a smart contract on Ethereum L1, \n which requires a gas fee. NFTs minted \nhere remain on Loopring L2 until deployed.",
  labelNFTDescribe: "Description:",
  labelNFTTitle: "Amount",
  labelNFTDepositInputTitle: "Amount:",
  labelNFTTId: "NFT Token ID:",
  labelNFTCid:
    "IPFS CIDv0 or dag-pb CIDv1:(Store Metadata Information) <1>Follow this Guide </1>",
  labelNFTType: "Token Standard:",
  labelNFTAccess: "Allow Loopring to spend {{symbol}}",
  labelDeployDenied: "Signature request was rejected",
  labelNFTTokenDeployWaitForAuth: "Allow Loopring to deploy {{symbol}}?",
  labelDeployFailed: "Deploy of {{symbol}} has failed!",
  labelDeploySubmit: "Deploy of {{symbol}} has been submitted!",
  labelMint: "Mint",
  labelMintDenied: "Signature request was rejected",
  labelNFTTokenMintWaitForAuth: "Allow Loopring to mint {{symbol}}?",
  labelMintFailed: "Mint of {{symbol}} has failed!",
  labelMintSuccess: "Mint of {{symbol}} has been submitted!",
  labelNFTMintBtn: "Mint My NFT",
  labelNFTMintNoMetaBtn: "Wrong Metadata",
  labelNFTMintNoMetaDetail:
    "Your NFT metadata should identify <1>name, image, and royalty_percentage (integer from 0 to 10)</1>.",
  nftDeployDescription: "Deploy NFT",
  nftDeployTitle: "Deploy NFT",
  nftMintTitle: "Mint NFT",
  nftMintBtn: "Mint NFT",
  labelMintInProgress: "Processing...",
  labelNFTDeployBtn: "Deploy NFT",
  labelNFTDeployBroker: "Deploy Broker:",
  labelDeployInProgress: "Processing...",
  labelNFTDeployTitle: "Deploy NFT",
  labelVendor: "Buy with Card",
  labelLock: "Lock",
  labelWalletToWallet:
    "The connected wallet is a contract address which cannot be used (Except Recover Wallet). If you are connecting a mobile Loopring Smart Wallet, you can protect it and manage guardians within the app.",
  labelWalletAddAsGuardian: "Add a guardian",
  labelWalletInputGuardianCode: "Input 6 digital Code and Approve",
  labelWalletScanQRCode: "Please use your Loopring wallet to scan the QR code",
  labelWalletInputGuardianCodeDes:
    "Please contact the owner to obtain the approval code and enter it below.",
  labelWalletGuardianList: "Guardian List",
  labelAddProtector: "add Guardian",
  labelUnknown: "Unknown",
  labelApprove: "Approve",
  labelReject: "Reject",
  labelWalletApprove: "Approve Signature",
  labelCommonList: "Waiting for your Approve List",
  labelLogList: "Log List",
  labelWalletReject: "Reject Signature",
  labelLockAccountSuccess: "Lock Account Success",
  labelLockAccountFailed: "Lock Account Failed",
  labelApproveSuccess: "Approve Signature Success",
  labelApproveFailed: "Approve Signature Failed",
  labelRejectSuccess: "Reject Signature Success",
  labelRejectFailed: "Reject Signature Failed",
  labelYourBalance: "Your Layer 2 have: {{balance}}",
  labelTxGuardianADD_GUARDIAN: "ADD GUARDIAN",
  labelTxGuardianGUARDIAN_CONFIRM_ADDITION: "GUARDIAN CONFIRM ADDITION",
  labelTxGuardianGUARDIAN_REJECT_ADDITION: "GUARDIAN REJECT ADDITION",
  labelTxGuardianGUARDIAN_APPROVE: "GUARDIAN APPROVE",
  labelTxGuardianAPPROVE_RECOVER: "RECOVER WALLET", // RECOVER  16
  labelTxGuardianAPPROVE_TRANSFER: "OVER DAILY QUOTA TRANSFER", // APPROVE TRANSFER 18
  labelTxGuardianAPPROVE_TOKEN_APPROVE: "TOKEN ACCESS", // 23
  labelTxGuardianADD_GUARDIAN_WA: "ADD GUARDIAN", // 34
  labelTxGuardianREMOVE_GUARDIAN_WA: "REMOVE GUARDIAN", // 35
  labelTxGuardianUNLOCK_WALLET_WA: "UNLOCK WALLET", // 37
  labelTxGuardianRESET_GUARDIANS_WA: "RESET GUARDIANS", // 200
  labelTxGuardian_recovery: "recovery wallet",
  labelTxGuardian_transfer: "over daily quota transfer",
  labelTxGuardian_add_guardian: "add guardian",
  labelTxGuardian_remove_guardian: "remove guardian",
  labelTxGuardian_unlock_wallet: "unlock wallet",
  labelTxGuardian_deposit_wallet: "deposit",
  labelTxGuardianApprove: "APPROVE",
  labelTxGuardianReject: "REJECT",
  labelReActiveAccount: "Re-Activate Account",
  labelWalletSignType: "Request for {{type}}",
  labelSpotTrading: "Spot Trading Volume (30d in ETH)",
  labelTradeSpot: "Trade Spot",
  labelBuyToken: "Buy {{token}}",
  labelCurrentlyLevel: "Currently {{value}} {{token}}",
  labelLRCBalance: "LRC Balance",
  labelNoticeForForAccountFrozen: "Please wait while {{ type }} is updating.",
  labelAction: "action",
  labelGoExplore: "View transactions on the <1>Loopring Block Explorer</1>.",
  labelNOETH: "Need ETH for gas",
  labelBanxaFeeFree: "zero fees for a limited time",
  labellimit: "limit",
  labelmarket: "Market",
  labelswap: "Swap",
  labelamm: "Amm",
  labelActiveAccountTitle: "Activate Loopring L2 Account",
  depositTitle: "Add Assets from my L1",
  depositTitleAndActive: "Add Asset from My L1 & Activate",
  depositAndActiveBtn: "Activate Loopring L2",
  depositTitleActive: "Activate Loopring L2",
  depositLabelBtn: "Receive",
  depositLabelLinkRecent: "Recent receive history from L1",
  labelL2ToL1Title: "Send to L1",
  labelL2ToMyL1Title: "Send to My L1",
  labelL2ToOtherL1Title: "Send to Another L1",
  labelL2ToL1DeployTitle: "Deploy & Send to L1",
  labelL2toL1EnterToken: "Select Token",
  labelSendL1Btn: "Send",
  labelSendL1DeployBtn: "Deploy & Send",
  labelL2toL1BtnExceed: "Exceed Max Fast Withdraw Amt{{amt}}!",
  labelL2toL1Address: "Ethereum Address",
  labelL2toL1MyAddress: "To my L1",
  labelL2toL1AddressInput: "Please input the address",
  labelL2toL1Fee: "Select payment token",
  labelL2toL1Fast: "Fast",
  labelL2toL1Standard: "Standard",
  labelL2toL1LinkRecent: "Recent withdrawal history",
  labelL2toL2Title: "Send to Another Loopring L2",
  labelL2toL2EnterToken: "Select Token",
  transferDescription:
    "Send assets to any valid Ethereum address instantly.\n Please make sure the recipient address accepts \n Loopring L2 payments before you proceed.",
  labelL2toL2Btn: "Send",
  labelL2toL2Address: "Recipient",
  labelL2toL2AddressInput: "Please input address / ENS / Account ID",
  labelL2toL2Memo: "Memo (Optional)",
  labelL2toL2MemoPlaceholder: "Please input the memo",
  labelL2toL2FeeChoose: "Select payment token",
  labelL2toL2Fee: "Fee",
  labelL2toL2FeeNotEnough: "Insufficient balance",
  labelL2toL2LinkRecent: "Recent send history",
  labelActiveLayer2: "Activate Loopring L2",
  labelAddAsset: "Receive",
  labelAddAssetBtn: "Receive",
  labelSendAsset: "Send",
  labelSendAssetBtn: "Send",
  labelSend: "Send",
  labelReceive: "Receive",
  labelWaitingRefer: "Waiting for approval",
  labelL1toL2WaitForAuth:
    "Please confirm to receive {{value}} {{symbol}} to {{to}} Loopring L2.",
  labelL1toL2Denied: "You rejected to receive {{value}} {{symbol}}.",
  labelL1toL2Failed: "Add asset request of {{value}} {{symbol}} failed!",
  labelL1toL2Submit: "Add asset request has been submitted. <1></1>",
  labelL1toL2NeedApprove: "Allow Loopring Exchange to spend {{symbol}}",
  labelL2toL1InProgress: "Processing...",
  labelL2toL1Failed: "Sent {{value}} {{symbol}} to L1 has failed!",
  labelL2toL1Success: "Sent {{value}} {{symbol}} to L1 was successful!",
  labelL2toL2InProgress: "Processing...",
  labelL2toL2Failed:
    "Sent {{value}} {{symbol}} from my Loopring L2 to another Loopring L2 failed!",
  labelL2toL2Success: "Sent {{value}} {{symbol}} was successful!",
  labelUpdateAccountFailed: "Activate Loopring L2 has failed!",
  labelCreateAccountSubmit:
    "Activation of Loopring L2 with deposit of {{value}} {{symbol}} has been submitted! \n Approximately {{count}} minutes remaining...',",
  labelCreateAccountFailed:
    "Activation of Loopring L2 with deposit of {{value}} {{symbol}} has failed!",
  labelL1toL2Hash: "My L1 \u2192 Loopring L2 Transaction Hash",
  labelL1toL2HashEmpty:
    "My L1 \u2192 Loopring L2 transactions will show up here.",
  labelL1toL2Record: "Receive {{value}} {{symbol}}",
  labelNFTSendL2Btn: "To Another Loopring L2",
  labelNFTSendMyL1Btn: "To My L1",
  labelNFTSendOtherL1Btn: "To Other L1",
  labelNFTDeploySendMyL1: "To My L1 & Deploy Contract",
  labelNFTDeploySendAnotherL1: "To another L1 & Deploy Contract",
  labelGuid: "Go to Guide",
  labelOK: "Ok",
  labelL2toL2InvalidAddress: "Invalid address or ENS",
  labelL2toL2AddressNotLoopring:
    "This address has not yet activated Loopring L2. Please make sure that the recipient can access Loopring L2 since the funds will be sent there.",
  labelL2toL2AddressType: "Address Type",
  labelL2toL2OriginDesc:
    "Please select the address source. Note: the following trading platforms currently do not support Loopring L2 transfers (Binance, Huobi, Okex…)",
  labelL2toL2OriginBtnExchange: "Exchange",
  labelL2toL2OriginBtnWallet: "Wallet",
  labelL2toL2Confirm: "Confirm",
  labelL2toL2TokenAmount: "Token Amount",
  labelL1toL2ThirdPart: "Buy Crypto with",
  labelActiveAccountFeeNotEnough: "Insufficient balance <1>Add assets</1>",
  labelNFTTransferTX: "L2 \u2192 L2",
  labelNFTWithdrawTX: "L2 \u2192 L1",
  labelNFTDepositTX: "L1 \u2192 L2",
  labelNFTDeposit: "Receive Loopring L2 NFT",
  labelNFTDepositNeedApprove:
    "Allow Loopring to spend {{symbol}} and deposit it?",
  labelNFTDepositBtn: "Receive NFT",
  nftDepositTitle: "Receive NFT from my L1",
  labelNFTContractAddress: "Contract:",
  labelNFTAmount: "Amount:",
  labelNFTTokenDepositWaitForAuth:
    "Please confirm to send Loopring L2 {{symbol}}",
  nftMintDescription:
    "Paste in the CID that you obtained from uploading \n the metadata.json file (point 11 above) - if successful,\n the data from the metadata.json file you created contained\n within the folder will populate the Name\n and Image below.",
  labelNFTMintInputTitle: "Amount <1>\uFE61</1>",
  labelL1toL2Vendor:
    "Use a Loopring partner to deposit funds.\nOnce your order is confirmed by Loopring,\n it will be added to your balance within 2 minutes.",
  depositLabelTo: "To address, Account ID or ENS.",
  labelAddressNotLoopring: "Account doesn't have an active Loopring L2",
  labelMINTNFTTitle: "Create NFT (ERC1155)",
  labelIPFSUploadTitle: "Upload Image <1>\uFE61</1> ",
  labelLoadDes: "Drag or click to upload files ({{types}}, max size: 10MB)",
  labelUpload: "upload",
  labelMintNoImageBtn: "Please upload image",
  labelMintUserAgree: "Please agree to the terms of service",
  labelMintTradeValueBtn: "Please input amount(1 - 10,000)",
  labelMintNoRoyaltyPercentageBtn: "Please input Royalty",
  labelMintWrongRoyaltyBtn: "Royalty should be (0 - 10)",
  labelMintNoNameBtn: "Please input name",
  labelNFTMetaBtn: "Upload metadata & mint",
  labelMintName: "Name <1>\uFE61</1>",
  labelMintCollection: "Collection (coming soon) <1>\u2139</1>",
  labelMintCollectionTooltips:
    "This is the collection where your NFT will appear.",
  labelMintRoyaltyPercentage: "Royalty (%) <1>\u2139</1>",
  labelMintRoyaltyPercentageRange: "Max Int:",
  labelMintRoyaltyPercentageTooltips:
    "Represents the percentage to be received from each subsequent resale (max 10%).",
  labelMintDescription: "Description <1>\u2139</1>",
  labelMintDescriptionTooltips:
    "The description will be included on the NFT's detail page beneath it's image.",
  labelMintProperty: "Properties (Limit 64) <1>\u2139</1>",
  labelMintPropertyTooltips:
    "Tags can be added to the NFT for easy searchability and distinction",
  labelPropertyAdd: "Add property",
  labelMintNFT: "Create NFT",
  labelL1toL2NFT: "Receive NFT",
  labelMyAssetsNFT: "My NFTs",
  labelTransactionNFT: "Transactions",
  labelMintPropertyKey: "Key",
  labelMintPropertyValue: "Value",
  labelNFTProperty: "Properties:",
  labelConfirmMint: "Confirm Metadata",
  labelUseIpfsMintAgree:
    "I confirm that the NFT minted does not infringe on copyright laws or contain illegal, explicit, sensitive, adult themed, or any other content considered NSFW. We reserve the right to hide inappropriate content if an NFT is discovered to be harmful.",
  labelL1toL2TitleBridge: "Add Loopring L2 Assets",
  labelL1toL2TitleBridgeNoConnect:
    "Connect your Ethereum L1 Wallet to transfer assets to any Loopring L2 account",
  labelPayer: "My Wallet:",
  labelL1toL2TokenAmount: "Token Amount",
  labelL1toL2From: "From",
  labelL1toL2TO: "To Loopring L2",
  labelAddAssetTitle: "Add Loopring L2 {{symbol}} assets",
  labelSendAssetTitle: "Send Loopring L2 {{symbol}} assets",
  labelAddAssetHowto: "How would you like to add Loopring L2 assets?",
  labelAddAssetTitleActive: "Add assets & Activate",
  labelFromMyL1: "From my L1 account",
  labelFromOtherL1: "From another L1 account",
  labelBuyWithCard: "Buy with Card",
  labelFromOtherL2: "From another Loopring L2 account",
  labelFromExchange: "Buy from Exchange",
  labelOpenInWalletApp: "Open in wallet app/extension",
  labelConnectWithDapp: "Connect with Dapp",
  labelOpenInWalletTitle: "Open in wallet",
  labelOpenInWalletDetail: `URL for adding funds has been copied. You can choose either way to continue:`,
  labelOpenInWalletDetailLi1: `Open your wallet app and paste the URL into its internal Dapp browser`,
  labelOpenInWalletDetailLi2: `Open your desktop Chrome browser and paste the URL in Chrome`,
  labelActiveL2Btn: "Activate Loopring L2",
  labelWrongNetworkGuideTitle: "Wrong Network",
  labelWrongNetworkGuide:
    "Your chosen network is not currently supported on Loopring. Please choose Ethereum main Network or test Network Goerli",
  labelSenAssetTitle: "Send {{symbol}} from Loopring L2",
  labelSendTOL2: "To another Loopring L2 account",
  labelSendToMyL1: "To my L1 account",
  labelSendToOtherL1: "To another L1 account \n(incl. exchange)",
  labelSendAssetHowto: "Where would you like to send your crypto to",
  labelL1toL2: "Add Loopring L2 assets From My L1",
  labelActivatedAccountChargeFeeList:
    "Please make sure one of the below tokens with the minimum quantity is in your Loopring L2 account to proceed:",
  labelReceiveAddress: "Receive Address",
  labelAssets: "Loopring L2 Assets",
  labelReceiveAddressGuide:
    "Please send {{symbol}} funds from a Loopring L2 address",
  labelL2toL2: "Send to another Loopring L2",
  labelL2toL1: "Send to L1",
  labelBenefitL2:
    "As Ethereum's first ever zkRollup, Loopring L2 allows you to avoid costly gas fees and network congestion with the same security as mainnet - 100x cheaper and faster.\n\nActivating your Loopring L2 account requires a small payment fee. ",
  labelNotBalancePayForActive:
    "Insufficient balance in your Loopring L2 account",
  labelEnoughBalancePayForActive:
    "You have enough balance to pay for Loopring L2 creation.",
  labelHaveInProcessingL1toL2:
    "If you have already started the deposit, please be patient and recheck as transactions on Ethereum can take up to 30 minutes.",
  labelWaitingL1toL2: "Please wait",
  labelAddAssetGateBtn: "Add assets",
  labelActiveLayer2Btn: "Activate Loopring L2",
  labelActiveLayer2PayBtn: "Pay Activation Fee",
  labelBalanceActiveAccountFee:
    "{{symbol}}: <2>Fee {{fee}};</2><3>My Loopring L2 balance: {{count}}</3>",
  labelToAddressShouldLoopring: "To address is no Loopring L2",
  labelBridgeSendTo: "Send to (address, Account ID or ENS)",
  labelInvalidAddressClick:
    "Invalid Wallet Address, {{way}} of {{token}} is disabled! <1>Click to input another receive address </1>",
  labelENSShouldConnect:
    "Receive address is an ENS, please connect wallet to check real address",
  labelToken: "Token",
  labelMinRequirement: "Min Requirement",
  labelAvailability: "Availability",
  labelWhatProvider: "Which provider would you like to use?",
  labelMemo: "Memo",
  labelAdvanceMint: "Advance Create",
  labelWalletTypeDes:
    "Please confirm the address origin again to ensure the assets are not mistakenly sent to the exchange address. ",
  labelWalletTypeOptions: "{{type}} Wallet",
  labelWalletTypeOtherSmart: "Other Smart",
  labelWalletTypeLoopring: "Loopring",
  labelWalletTypeEOA: "EOA",
  labelWalletTypeExchange: "Exchange",
  labelEOADes:
    "There is no smart contract binds with this wallet address. (e.g. MetaMask, imtoken, Ledger, Trezor, etc....) ",
  labelLoopringDes:
    "This wallet is created using Loopring Wallet mobile app and binds with Loopring smart contract.",
  labelOtherSmartDes:
    "This wallet binds with smart contract that does not support Loopring Layer 2. You will need to send funds to the L1 account. ",
  labelExchangeDes:
    "The following trading platforms currently do not support Loopring L2 transfers (Binance, Coinbase, FTX, etc...). You will need to send funds to the L1 account. ",
  labelExchangeTypeDes: "Please select the address source:",
  labelNonExchangeTypeDes:
    "eg: Loopring Wallet, Metamask, Coinbase Wallet, imtoken, Ledger, Trezor... EOA wallet",
  labelNonExchangeType: "Non-Exchange Wallet",
  labelExchangeType: "Exchange",
  labelExchangeBinance: "Binance",
  labelExchangeBinanceDes: "",
  labelExchangeHuobi: "Huobi",
  labelExchangeHuobiDes: "Transactions need to wait 24 hours",
  labelExchangeCoinbase: "Coinbase",
  labelExchangeOthers: "Others",
  labelExchangeOthersDes: "",
  labelL2toL1AddressType: "Address Type",
  labelConfirmCEX: "Confirm CEX Support",
  labelConfirmDetail:
    "<0>Before withdrawing, please confirm with your CEX support that they accept deposits from smart contracts.</0>" +
    "<1>L2 to L1 withdrawing is performed via a smart contract. The CEX depositing address may not be able to automatically acknowledge the deposit.</1>" +
    "<2>If the deposit does not appear at the CEX address within 24 hours, please contact your CEX support and ask they manually acknowledge the transaction.</2>",
  labelCEXUnderstand: "I understand and acknowledge the risk",
  labelMintFee: "Mint Fee",
  labelMintFeeNotEnough: "Insufficient balance",
  labelMintFeeChoose: "Select payment token",
  labelLayerSwapUnderstand: "Acknowledge and understand the risk",
  labelIUnderStand: "I Understand",
  labelLayerSwapUnderstandDes:
    "LayerSwap is a 3rd party App service provider to help move tokens from exchange to Loopring L2 directly. If you have any concerns regarding their service, please check out their <1>TOS</1>.",
  labelInvestAmmTitle: "AMM Pools",
  labelInvestBalanceTitle: "My Investments",
  labelTransactionsLink: "Transactions",
  labelAMMTransactionsLink: "View Pool Transactions",
  labelNFTMintWrongCIDBtn: "Wrong MetaData format",
  labelWithdrawBtn: "Withdraw",
  labelForceWithdrawTitle: "Force Withdraw",
  labelFWithdrawFee: "Fee",
  labelFWithdrawNotEnough: "Insufficient balance",
  labelForceWithdrawWaitForAuth: "Please confirm to force withdraw {{symbol}}",
  labelForceWithdrawDenied: "You rejected to force withdraw {{symbol}}.",
  labelForceWithdrawInProgress: "Processing...",
  labelForceWithdrawFailed: "Force withdraw has failed!",
  labelForceWithdrawSubmit: "Force withdraw has been submitted",
  labelForceWithdrawToken: "Token Amount",
  labelForceWithdrawFee: "Fee",
  labelForceWithdrawEnterToken: "Select Token",
  labelPleaseForceWithdrawAddress:
    "Please enter the address you wish to withdraw from",
  labelForceWithdrawAddress: "The address you wish to withdraw from",
  labelForceWithdrawDes:
    "If the recipient doesn't have an active Loopring L2 account, you will be able to withdraw the token from L2 to Ethereum L1. This process is usually only needed when tokens were sent to a CEX address using Loopring L2. Since the CEX does not have access to the L2 account, you will need to perform this action to reclaim the tokens.",
  labelForceWithdrawConfirm:
    "This feature allows a user to move their L2 tokens to the L1 address. The target address must either be a wallet or exchange address",
  labelForceWithdrawConfirm1:
    "This operation usually requires more than 30 minutes to take effect, as it needs to interact with Ethereum Mainnet. Please be patient.",
  labelNFTSendBtn: "Send",
  labelNFTProperties: "Properties",
  labelNFTDescription2: "Description",
  labelForceWithdrawNotAvailable:
    "Loopring L2 account is activated in this address. For security reason, Loopring would not allow other user to force withdraw token from its L2 to L1 anymore",
  labelForceWithdrawNoToken:
    "No token is detected from this address to operate",
  labelForceWithdrawBtn: "Force Withdraw",
  labelInvestDefiTitle: "ETH Staking",
  labelInvestDefDeposit: "Subscribe",
  labelInvestDefWithdraw: "Redeem",
  labelNFTDepositLabel: "Receive NFT",
  labelDefiFee: "Fee",
  labelDefiMin: "Minimum of {{arg}}",
  labelDefiNoEnough: "Not enough {{arg}}",
  labelDefiMaxBalance:
    "It is not possible for the Loopring pool to fulfil your complete request at the moment. You can only redeem {{maxValue}} now.\n" +
    "You can choose one of the following approaches for the remaining amount:",
  labelDefiMaxBalance1:
    "<0>" +
    "<1>Withdraw WSTETH to L1 and trade trade through Uniswap, 1Inch or Lido.</1>" +
    "<2>The Loopring pool will rebalance soon. Please come back later to redeem.</2>" +
    "</0>",
  labelDefiNoBalance:
    "<0>It is not possible for the Loopring pool to fulfil your complete request at the moment.</0>" +
    "<1>You can choose one of the following approaches for the remaining amount:</1>",
  labelDefiNoBalanceList:
    "<0>Withdraw wstETH to L1 and trade trade through Uniswap, 1Inch or Lido.</0>" +
    "<1>The Loopring pool will rebalance soon. Please come back later to redeem.</1>",
  labelDefiMaxBalanceJoin:
    "The quota is almost sold out and can't fulfil your complete order. You can only subscribe {{maxValue}} now. Loopring will setup the pool soon, please revisit for subscription later. ",
  labelDefiNoBalanceJoin:
    "Loopring will set up the pool soon. Please come back later to subscribe.",
  labelInvestBtn: "Subscribe",
  labelRedeemBtn: "Redeem",
  labelVipTitle: "VIP",
  labelSecurity: "Security",
  labelFeeTitleList: "Fee",
  labelInvestOverviewTitle: "Overview",
  labelTitleOverviewToken: "Total Investment Tokens",
  labelInvestType_AMM: "AMM Pool",
  labelInvestType_STAKE: "ETH Staking",
  labelInvestFlexible: "Flexible",
  labelInvestDuration: "Duration",
  labelDefiOrderTable: "ETH Staking",
  labelTitleMyInvestAvailable: "My Holding Tokens",
  labelViewMore: "View more",
  labelInvestSuccess: "Successfully {{type}} {{symbol}}",
  labelInvestFailed: "Subscribe Failed",
  labelDefiRiskTitle: "What is ETH Staking via Lido?",
  labelDefiRisk:
    "<0>Lido is a liquid staking solution for ETH 2.0 backed by industry-leading staking providers. Lido lets users stake their ETH - without locking assets or maintaining infrastructure.</0>\n" +
    "<1>When using Lido to stake your ETH on the Ethereum beacon chain, users will receive a token (stETH), which represents their ETH on the Ethereum beacon chain on a 1:1 basis. It effectively acts as a bridge bringing ETH 2.0’s staking rewards to ETH 1.0.</1>\n" +
    "<2>wstETH is the wrapped version of stETH. The total amount of wstETH doesn't change after users receive the token. Instead, the token’s value increase over time to reflect ETH staking rewards earned.</2>\n",
  labelDefiRisk2:
    "<0>It is important to note that users can't redeem wstETH for ETH until phase 2 of Ethereum 2.0. However, users are able to trade wstETH for ETH on various exchanges at market prices.</0>" +
    "<1>Loopring will provide a pool to allow users to trade wstETH for ETH directly on Layer 2. The pool will rebalance periodically when it reaches a specific threshold. If there is not enough inventory on Layer 2, user can always withdraw their wstETH tokens to Layer 1 and swap for ETH in Lido, Curve, or 1inch.</1>",
  labelDefiAgree: "I have read risk warning",
  labelDefiInvest: "Defi Earn",
  labelDefiClose:
    "ETH staking service is not available currently. Please stay tuned until the pool is setup. Usually it will be ready within hour.",
  labelCreateCollection: "New My Collection",
  labelCollectionCreateName: "Contract address for your collection",
  labelCollectionCreateERC1155: "Collection ERC-1155",
  labelCollectionCreateWaiting: "Waiting for create Collection token Address",
  labelMintSelect: "Choose Creation Method",
  labelMintSelectDes: "Choose the most suitable approach for your needs.",
  labelCollectionCreateFailed: "Create Collection token Address Failed",
  labelCollectionMetaTitle: "Import Metadata from IPFS",
  labelAdvanceCreateCollection: "Advance Create Collection",
  labelCreateCollectionSuccess: "Collection create was successful",
  labelCreateCollectionFailed: "Collection create has failed",
  labelCollectionAdvanceJSON: "NFT Collection information follow this format: ",
  labelCopyDemo: "Click to copy the demo",
  labelCollectionCreatBtn: "Create Collection",
  labelEnterMeta: "Enter Collection Metadata",
  labelMintGuid:
    'Fill up content in GUI and let Loopring to generate necessary metadata and upload to IPFS for you, then use "Mint" to create your NFT.',
  labelAdMintGuid:
    'Generate all the required metadata and upload to IPFS by yourself first, then use "Advanced Mint" to create your NFT.',
  labelFilterTradeNFTSell: "Sell",
  labelFilterTradeNFTSelf: "Self Trade",
  labelFilterTradeNFTBuy: "Buy",
  labelAdMintTitle: "Advance Create Collection",
};
